import { getKcContext } from "keycloakify/lib/getKcContext";

export const { kcContext } = getKcContext<
  // NOTE: A 'keycloakify' field must be added
  // in the package.json to generate theses pages
  // https://docs.keycloakify.dev/build-options#keycloakify.extrapages
  | { pageId: "otp-email-form.ftl"; }
  // NOTE: register.ftl is deprecated in favor of register-user-profile.ftl
  // but let's say we use it anyway and have this plugin enabled: https://github.com/micedre/keycloak-mail-whitelisting
  // keycloak-mail-whitelisting define the non standard ftl global authorizedMailDomains, we declare it here.
  | { pageId: "register.ftl"; authorizedMailDomains: string[]; }
  | { pageId: "login.ftl"; authorizedMailDomains: string[]; }
  | { pageId: "error.ftl"; authorizedMailDomains: string[]; }
>({
  // Uncomment to test the login page for development.
  // "mockPageId": "login-reset-password.ftl",
  // 'mockPageId': 'error.ftl',
  // 'mockPageId': 'login.ftl',
  // "mockPageId": "info.ftl",
  // "mockPageId": "login-update-password.ftl",
  // "mockPageId": "login-page-expired.ftl",
  // "mockPageId": "login-update-profile.ftl",
  // "mockPageId": "update-user-profile.ftl",
  // "mockPageId": "logout-confirm.ftl",
  // "mockPageId": "login-verify-email.ftl",
  'mockPageId': 'otp-email-form.ftl',
  "mockData": [
    {
      "pageId": "otp-email-form.ftl",
      "locale": {
        "currentLanguageTag": "ru"
      },
      // actionUri: undefined,
      // pageRedirectUri: undefined,
      // actionUri: undefined,
      // messageHeader: "Perform the following action(s)",
      message: {
        type: "info",
        summary: "Слишком частое запрашивание одноразового кода"
      }
    }]
});

export type KcContext = NonNullable<typeof kcContext>;
